<template lang="pug">
.fixed.inset-0.z-10.table.w-full.h-full.bg-windowShadow
  .table-cell.align-middle
    .mx-auto.shadow.p-6.bg-white.flex.flex-col.overflow-y-auto(style='width: 428px; height:76vh')
      .flex.justify-between
        span.col-start-1.col-end-2.font-bold.text-darkblue.text-xl.font-SourceSansPro.whitespace-nowrap {{ $t("setting-company") }}
        i.el-icon-close.text-2xl.cursor-pointer.text-darkblue.font-semibold(
          @click='$emit("close")'
        )
      span.text-annotationColor.font-SourceSansPro.text-sm.font-semibold.mt-3 {{ $t("company.name") }}
      el-input(v-model='settingCompanyForm.name')
      span.text-annotationColor.font-SourceSansPro.text-sm.font-semibold.mt-3 {{ $t("company.timeZone") }}
      el-select.w-full(v-model='settingCompanyForm.tz')
        el-option(
          v-for='item in getTZ',
          :key='item.key',
          :label='item.value',
          :value='item.key'
        )
      span.text-annotationColor.font-SourceSansPro.text-sm.font-semibold.mt-3(
        v-if='model.active_company.dealer && !model.active_company.dealer.is_default && role === "CONTROLLER"'
      ) {{ $t("company.integrator") }}

      el-select.w-full(
        v-if='model.active_company.dealer && !model.active_company.dealer.is_default && role === "CONTROLLER"'
        v-model='settingCompanyForm.integrator'
      )
        el-option(
          v-for='item in integrators',
          :key='item.id',
          :label='item.name',
          :value='item.id'
        )
        //tarif start

      .w-full(v-if='billing && tariff.price !==0')
        .w-full(v-if='tariff.units !== 0 ')
          el-collapse.tarif-collapse(v-model='activeCollapsTarif')
            .my-1
              el-collapse-item(:title='$t("tariff.information") ')
                template(slot='title')
                .flex.items-center.w-full
                  .flex.flex-col(style='width:50%')
                    span.text-annotationColor.font-SourceSansPro.text-sm.font-semibold.mr-2.mt-3 {{ $t('company.accounting_type') }}
                    span.mt-1 {{ tariff.type ==='charge_all_units' ? $t('company.by_number_objects') : $t('company.by_number_objects_sent_data') }}
                  .flex.flex-col
                    span.text-annotationColor.font-SourceSansPro.text-sm.font-semibold.mr-2.mt-3 {{$t("tariff.balance")}}
                    span.mt-1 {{ amount }} {{ getTemplateCurrency }}
                .flex.items-center.w-full(v-if='companyStatus')
                  .flex.flex-col(style='width:50%')
                    span.text-annotationColor.font-SourceSansPro.text-sm.font-semibold.mr-2.mt-3 {{ $t('company.max_number_objects') }}
                    span.mt-1 {{ tariff.units }}
                  .flex.flex-col(style='width:50%')
                    span.text-annotationColor.font-SourceSansPro.text-sm.font-semibold.mr-2.mt-3 {{ $t('company.cost_one_object') }}
                    span.mt-1 {{ tariff.price }} {{ getTemplateCurrency }}
                .flex.items-center.w-full(v-if='companyStatus')
                  .flex.flex-col(style='width:50%')
                    span.text-annotationColor.font-SourceSansPro.text-sm.font-semibold.mr-2.mt-3 {{ $t('company.start_current_period') }}
                    span.mt-1 {{ model.active_company.billing.start_date }}
                  .flex.flex-col(style='width:50%')
                    span.text-annotationColor.font-SourceSansPro.text-sm.font-semibold.mr-2.mt-3 {{ $t('company.payment_period') }}
                    span.mt-1 {{ getDays(tariff)}}
                //- .flex.items-center.w-full.mb-4
                //-   .flex.flex-col(style='width:50%')
                //-     span.text-annotationColor.font-SourceSansPro.text-sm.font-semibold.mr-2.mt-3 {{ companyStatus === "TRIAL" ? $t("tariff.trial")  :  companyStatus === "ACTIVE" ? $t("tariff.paid") : $t("tariff.company-blocked")}}
                //-     span.mt-1(v-if='companyStatus && companyStatus !== "BLOCKED"') {{validTo.substring(0,validTo.length - 8)}}

                .mt-2
                  span.nextPayment.font-bold(v-if='companyStatus && companyStatus !== "BLOCKED"')  {{nextPaymentText}}
                p.font-normal( style='color:#C5CED7' v-if='model.active_company.billing.tariff.last_total ') {{ $t('company.previous_period_amount') }} {{ model.active_company.billing.tariff.last_total + ' ' + getTemplateCurrency }} .
                //- span.nextPayment(v-if='companyStatus && companyStatus !== "BLOCKED"')  {{$t("tariff.next-payment")}} {{validTo.substring(0,validTo.length - 8) + '   ' + rate + " "   }}  {{currency ==='RUB' ? $t("tariff.rouble") : $t("tariff.rouble") }}
                //- autoPay
                autoPay.my-2(:currentPayementError='currentPayementError' :activeCompany='model.active_company' :is-auto-pay-active="isAutoPayActive" @editCard='handleEditCard' @addCard='handleAddCard' @deleteCard='handleDeleteCard' @autoPay='handleAutoPayActive')
                .flex.flex-col
                  span.cursor-pointer.text-darkblue.font-semibold.mb-3.mt-5(
                    @click='openTransactions'
                  ) {{$t("tariff.payment-history")}}
                  span.cursor-pointer.text-darkblue.font-semibold(
                    v-if='companyStatus==="BLOCKED"',
                    @click='isPayingBlocked = true'
                  ) {{$t("tariff.choose-pay")}}
                  span.cursor-pointer.text-darkblue.font-semibold(v-else @click='isPaying = true') {{$t("tariff.pay")}}


        .w-full(v-else)
          .flex.items-center.w-full.justify-between
            .flex.flex-col
              span.text-annotationColor.font-SourceSansPro.text-sm.font-semibold.mr-2.mt-3 {{$t("tariff.current")}}
              span.mt-1 {{ $t("tariff.notselected") }}
              span.cursor-pointer.text-darkblue.font-semibold.my-4(@click='isPayingBlocked = true')  {{ $t("tariff.choose-pay") }}
              //- span.cursor-pointer.text-darkblue.font-semibold.my-4(@click='isPaying = true')  {{ $t("tariff.choose-pay") }}
      .flex.items-center.mt-3
        span.text-annotationColor.font-SourceSansPro.text-sm.font-semibold.mr-2 {{ $t("company.exitTime") }} :
        el-tooltip(
          :open-delay='700',
          effect='dark',
          :content='$t("company.tooltip")',
          placement='bottom',
              :offset='0',
          :visible-arrow='false'
        )
          svg(
            width='15',
            height='15',
            fill='none',
            xmlns='http://www.w3.org/2000/svg'
          )
            path(
              fill-rule='evenodd',
              clip-rule='evenodd',
              d='M7.5 13.636c3.577 0 6.136-2.559 6.136-6.136S11.077 1.364 7.5 1.364 1.364 3.923 1.364 7.5s2.559 6.136 6.136 6.136zM7.5 15a7.5 7.5 0 110-15 7.5 7.5 0 010 15zM4.964 5.63c.05-1.264.913-2.22 2.527-2.22 1.47 0 2.46.873 2.46 2.064 0 .863-.434 1.47-1.169 1.91-.718.422-.924.717-.924 1.29v.34H6.706L6.7 8.569c-.027-.785.312-1.286 1.075-1.742.673-.407.912-.73.912-1.303 0-.629-.495-1.09-1.257-1.09-.769 0-1.264.461-1.314 1.196H4.964zm2.326 5.96a.715.715 0 01-.729-.717c0-.406.323-.718.73-.718.417 0 .734.312.734.718a.713.713 0 01-.735.718z',
              fill='#2F80ED'
            )

      skif-input-number(
        style="min-height:32px"
        width='full',
        v-model.number='settingCompanyForm.auto_kill_sessions'
      )
      .strongPassword(
        class="relative"
      )
        skif-checkbox.mt-3(
          v-model='settingCompanyForm.strong_password',
          :label='$t("company.passwordLabel")'
        )

        .hint-text(
          @focus="showText"
          @blur="hideText"
        )
          question-icon(
            class="ml-2 mt-2 cursor-pointer"
          )
          .text(
            class="absolute hidden"
          )
            p <b>1.</b> {{ $t('company.passwordLabelText1') }}
            <br/>
            p <b>2.</b> {{ $t('company.passwordLabelText2') }}
      skif-checkbox.mt-3(
        v-model='settingCompanyForm.efis_retranslate',

        :label='$t("company.retransmit")'
      )
      span.cursor-pointer.text-darkblue.font-semibold.my-4.uppercase(@click='getTerms')  {{ $t('company.download_offer') }}
      span.text-annotationColor.font-SourceSansPro.text-sm.font-semibold.mt-3 {{$t("company.date-format")}}
      el-select.w-full(v-model='settingCompanyForm.dateformat')
        el-option(
          v-for='(item, index) in dates',
          :key='index',
          :label='item',
          :value='item'
        )
      span.text-annotationColor.font-SourceSansPro.text-sm.font-semibold.mt-3 {{$t("company.time-format")}}
      el-select.w-full(v-model='settingCompanyForm.timeformat')
        el-option(
          v-for='(item, index) in times',
          :key='index',
          :label='item',
          :value='item'
        )
      span.text-annotationColor.font-SourceSansPro.text-sm.font-semibold.mt-3 {{ $t('company.last_date_widget') }}
      el-select.w-full(v-model='settingCompanyForm.formatTime' @change="changeFormatTime(settingCompanyForm.formatTime)")
        el-option(
          v-for='(item, index) in formatTime',
          :key='index',
          :label='item',
          :value='item'
        )
      span.widget-time.text-annotationColor.font-SourceSansPro.text-xs {{ $t('company.last_date_widget_note') }}
      .flex.justify-between.pb-5.mt-5
        skif-button(variant='normal', @click='$emit("close")') {{ $t("cancel") }}
        skif-button(@click='save') {{ $t("change") }}
    // payment History
    el-dialog.history-panel(
      :title='$t("tariff.payments-history") ',
      :visible.sync='dialogVisible',
      :modalAppendToBody='false',
      top='20vh',
      width='35%'
    )
      .flex.justify-center.align-center
        //- skif-search.mx-4(v-model='payementSearch', :placeholder='$t("search")')
        el-date-picker.mr-6(
          style='width: 80%',
          type='daterange',
          :placeholder='$t("routesTrips.trip.date.placeholder")',
          range-separator='-',
          value-format='yyyy-MM-dd HH:mm:ss',
          :clearable='false',
          v-model='paymentHistoryDateRange',
          popper-class='reportsDatepicker'
          :default-value='new Date()'
          @change='handlhistoryDate'
        )
      .tableContainer.overflow-y-auto.pr-6(style='height:50vh')
        .table-container.mt-4.w-full
          table.w-full.table-fixed
            thead.w-full
              th.bg-white.top-0.sticky.pl-2.text-left(style='width: 40%')
                span.font-bold.text-darkblue.text-xl.mr-2(
                  style='font-size: 14px; color: #3C5E8F',
                  @click='sortChange("name")'
                ) {{$t("tariff.date-time")}}
                  //- svg.ml-2.transition-transform.ease-in-out.duration-500(
                  //-   width='24',
                  //-   height='24',
                  //-   fill='none',
                  //-   xmlns='http://www.w3.org/2000/svg',
                  //-   :style='sortProp === "name" && sortOrder === "descending" && { transform: "scale(1,-1)" }'
                  //- )
                  //-   path.transition-colors.ease-in-out.duration-200(
                  //-     d='M6.395 20a.677.677 0 00.68-.682V5.683a.683.683 0 00-.68-.682.677.677 0 00-.679.682v13.635a.683.683 0 00.68.682z',
                  //-     :fill='sortProp === "name" ? "#3C5E8F" : "#91B4E7"'
                  //-   )
                  //-   path.transition-colors.ease-in-out.duration-200(
                  //-     d='M9.591 8.893a.682.682 0 000-.966L6.876 5.2a.679.679 0 00-.962 0L3.2 7.927a.683.683 0 00.74 1.114.68.68 0 00.221-.148l2.235-2.246L8.63 8.893a.679.679 0 00.961 0zm2.235 6.335a.683.683 0 01.679-.682h4.073a.677.677 0 01.679.682.683.683 0 01-.68.681h-4.072a.677.677 0 01-.68-.681zm0-4.09a.683.683 0 01.679-.683h6.788a.678.678 0 01.68.682.683.683 0 01-.68.682h-6.788a.678.678 0 01-.68-.682zm0-4.091a.683.683 0 01.679-.682h9.504a.678.678 0 01.678.682.683.683 0 01-.678.681h-9.504a.677.677 0 01-.68-.681zm0 12.271a.683.683 0 01.679-.681h1.357a.677.677 0 01.68.681.683.683 0 01-.68.682h-1.357a.677.677 0 01-.68-.682z',
                  //-     :fill='sortProp === "name" ? "#3C5E8F" : "#91B4E7"'
                  //-   )
              th.bg-white.top-0.sticky.bg-white.text-left(style='width: width: 20%')
                span.text-darkblue.leading-10.cursor-pointer(
                  style='font-size: 14px; color: #3C5E8F'
                ) {{$t("tariff.operation")}}

              th.bg-white.top-0.sticky.cursor-pointer.text-right(
                @click='sortChange("online")',
                style='background-color: white; width:30%'
              )
                span.text-darkblue.leading-10.cursor-pointer(
                  style='font-size: 14px; color: #3C5E8F'
                ) {{$t("tariff.amount")}}
              th.bg-white.top-0.sticky.cursor-pointer.text-right(
                style='font-size: 14px; color: #3C5E8F'
              ) {{ $t("tariff.objects") }}
            tbody(
              is='transition-group',
              move-class='transition duration-1000',
              style='border-top: 4px solid white'
            )
              tr.cursor-pointer.hover_bg-reallylightblue.border-b(
                style='border-color:#EEF5FF'
                v-for='(payment, i) in transactions',
                :key='i',
                )
                td.ellipsis.pl-2.text-left
                  el-tooltip(
                    :open-delay='700',
                    effect='dark',
                    :content='payment.timestamp',
                    placement='bottom',
              :offset='0',
                    :visible-arrow='false'
                  )
                    span.leading-10.nameTable {{ payment.timestamp }}
                td.ellipsis.text-left
                  .flex.flex-col
                    span.nameTable(:class='payment.amount > 0 ? "greeny" : "redy"') {{ payment.amount }}
                    span.nameTable {{ payment.payment_type ? payment.payment_type : '' }}
                td.ellipsis.text-right
                  span.leading-10.nameTable {{ payment.amount_after }} {{ getTemplateCurrency }}
                td.ellipsis.text-right
                  span.leading-10.nameTable {{ payment.units }}
  el-dialog.history-panel-pay.mb-4(
    :title=' $t("tariff.pay")',
    :visible.sync='isPaying',
    :modalAppendToBody='false',
    :close-on-click-modal="false"
    top='20vh',
    width='29%'
  )
    .flex.justify-start.flex-col.price
      span.mb-4.text-annotationColor.font-SourceSansPro.text-sm.font-semibold {{ '*' + $t("tariff.amounttopay")}}
      .flex.w-full.relative
        el-input.custom-input-payment(
          v-model.number='amountToPay'
        )
        span.font-SourceSansPro.text-sm.font-semibold.absolute.right-0.currency.mt-2 {{ $t('tariff.rouble') }}
      span.info {{ $t('company.minimum_payment_amount') }}
    .flex.cards-container.mt-4.mb-6
      skif-checkbox(v-model='isUseCard')
      span {{ $t('company.use_card_for_autopayments') }}
    .flex.flex-col.options-container(v-if='isUseCard')
      span.card-choose *{{ $t('company.select_payment_system') }}
      el-radio.mt-4(
        v-model='cardType',
        width='20',
        :label='$t("company.visa_or_mastercard")'
      )
      .mir-container.mb-5
        el-radio.mt-4(
            v-model='cardType',
            label='Система МИР'
          )
        span.info-card(v-if='cardType==="Система МИР"') ({{ $t('company.temporarily_unavailable') }})
      span.mb-6.txt-danger(v-if='isCardChoosenEmpty') {{ $t('company.choose_payment_system') }}
      span.mb-6.txt-danger(v-if='isMirError') {{ $t('company.autopayment_unavailable_with_mir') }}
    span.dialog-footer.flex.justify-end.mb-3(slot='footer')
      button.cursor-pointer.cancelBtn.mr-8(
        type='primary',
        @click='isPaying = false',
        Cancel
      ) {{$t("admintariff.cancel")}}
      button.acceptBtn.cursor-pointer(@click='pay') {{$t("admintariff.refill")}}
      form(ref='payment' v-if='isPayementReady' method="post" :action="currentActiveBilling")
        input(v-for="(value, name) in readyOrder" type="hidden" :name="name" :value="value")
  transition(name='fading' mode='in-out')
    billingForm(v-if='isPayingBlocked' @close='isPayingBlocked = false' :isblocked='true' @unblock="unblockCompany")

</template>

<script>
import { companyApi } from '@/api'
import moment from 'moment'
import { mapState, mapGetters, mapActions } from 'vuex'

const currentUrl = window.location.href

export default {
  components: {
    billingForm: () => import('../../units/subComponents/billingForm'),
    autoPay: () => import('./help-tools/autoPay'),
    questionIcon: () => import('./assets/QuestionIcon.vue')
  },
  props: ['admin', 'companysettings'],
  data() {
    const errorMessage = {}
    errorMessage.nameCompany = this.$t('loginPage.errors.nameCompany')
    const validateNameCompany = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(errorMessage.nameCompany))
      }
      callback()
    }
    return {
      formatTime: ['00:00:00', '23:59:59'],
      isAutoPayActive: false,
      cardType: '',
      isCardChoosenEmpty: false,
      isUseCard: false,
      isMirError: false,
      period: '',
      isPayingBlocked: false,
      activeCollapsTarif: [],
      isPayementReady: false,
      paymentHistoryDateRange: [new Date(), new Date()],
      dialogVisible: false,
      isPaying: false,
      amountToPay: 0,
      settingCompanyForm: {
        name: '',
        tz: '',
        auto_kill_sessions: '',
        integrator: '',
        strong_password: false,
        efis_retranslate: false,
        dateformat: '',
        timeformat: '',
        formatTime: '00:00:00'
      },
      dates: ['yyyy-MM-dd', 'd/MM/yy', 'dd-MM-yyyy'],
      times: ['HH:mm', 'HH:mm:ss'],
      rules: {
        name: [
          { validator: validateNameCompany, trigger: 'blur' },
          {
            min: 3,
            max: 125,
            message: 'Длина имени компании может быть от 3 до 125 символов'
          }
        ]
      },
      tariff: {},
      amount: '',
      companyStatus: '',
      validFrom: '',
      validTo: '',
      rate: '',
      isPayementHistory: false,
      sortProp: '',
      daysLeft: '',
      billing: false,
      currentPayementError: '',
      wasAutoPayActive: false,
      isPayementError: false,
      isCardDeleted: false,
      integratorId: ''
    }
  },
  computed: {
    ...mapState('login', {
      model: (state) => state.me
    }),
    ...mapState('login', {
      activeCompany: (state) => state.me
    }),
    ...mapGetters('login', ['role', 'me', 'currency']),
    ...mapState('properties', ['integrators']),
    ...mapState('billing', {
      order: (state) => state.order,
      transactions: (state) => state.transactions
    }),
    ...mapGetters('properties', ['getTZ']),
    ...mapState('company', {
      currentTime: (state) => state.currentTime
    }),
    getCurrencyCode() {
      return this.model.active_company.billing.currency.code
    },
    getCurrencyDisplayName() {
      return this.model.active_company.billing.currency.display_name
    },
    getTemplateCurrency() {
      return this.getCurrencyCode === 'RUB'
        ? this.$t('tariff.rouble')
        : this.getCurrencyDisplayName
    },
    nextPaymentText() {
      if (this.companyStatus && this.companyStatus !== 'BLOCKED') {
        const nextPayment = this.$t('tariff.next-payment')
        const validToSubstring = this.validTo.substring(
          0,
          this.validTo.length - 8
        )
        const { type, total } = this.model.active_company.billing.tariff

        const paymentDetails = type === 'charge_all_units' ? total : '~~~'

        return `${nextPayment} ${validToSubstring} (${paymentDetails} ${this.getTemplateCurrency})`
      }
      return ''
    },
    currentActiveBilling() {
      return this.model.active_company.billing.system.payment_url;
    },

    tariffType() {
      return this.model.active_company.billing.tariff.type
    },

    billAmount() {
      return this.model.active_company.billing.amount
    },

    readyOrder() {
      const arr = {}
      for (const i in this.order) {
        arr[i] =
          typeof this.order[i] === 'object'
            ? JSON.stringify(this.order[i])
            : this.order[i]
      }

      return arr
    },

    tariffPrice() {
      return this.me.active_company.billing.tariff.price
    }
  },
  watch: {
    currentPayementError(val) {
      this.isPayementError = true
    },
    isUseCard(val) {
      if (!val) {
        this.cardType = ''
      }
    },
    cardType(val) {
      if (val === 'Система МИР') {
        this.isMirError = true
      } else {
        this.isMirError = false
      }
      this.isCardChoosenEmpty = false
    },
    order(val) {
      this.isPayementReady = true
    },
    company(val) {
      val.forEach((item) => {
        if (
          this.companysettings.integrator &&
          this.companysettings.integrator.id === item.id
        ) {
          this.settingCompanyForm.integrator = item.id
        }
      })
    }
  },

  methods: {
    ...mapActions({
      createNewOrder: 'billing/CREATE_NEWORDER',
      createOrder: 'billing/CREATE_ORDER',
      getFormatTime: 'company/getFormatTime'
    }),
    changeFormatTime(formatTime) {
      localStorage.setItem('formattedTime', formatTime)
      this.getFormatTime(formatTime)
    },
    getDays(tariff) {
      let days
      if (tariff.length === 1) {
        days = this.$t('tariff.one_day')
      } else if (tariff.length >= 2 && tariff.length <= 4) {
        days =
          this.me.lang.key === 'en'
            ? this.$t('tariff.days')
            : this.$t('tariff.less_than_four_days')
      } else {
        days = this.$t('tariff.days')
      }
      return `${tariff.length} ${days}`
    },
    getTerms() {
      this.$store.dispatch('login/GET_TERMS', this.model.active_company_id)
    },
    handlhistoryDate() {
      const formData = {
        max_rows: 100,
        first_row: 0,
        from: this.paymentHistoryDateRange[0],
        to: this.paymentHistoryDateRange[1]
      }
      this.$store.dispatch('billing/GET_TRANSACTIONS', formData).then((res) => {
        console.log('date changed', this.transactions)
      })
    },
    openTransactions() {
      const formData = {
        max_rows: 100,
        first_row: 0
      }
      this.$store.dispatch('billing/GET_TRANSACTIONS', formData).then((res) => {
        console.log('request is Ready', this.transactions)
        this.dialogVisible = true
      })
    },
    pay() {
      if (!this.cardType && this.isUseCard) {
        this.isCardChoosenEmpty = true
      } else if (this.cardType !== 'Система МИР') {
        this.isMirError = true
        const formData = {
          amount: this.amountToPay,
          // success_url: 'https://dev.skif.pro/',
          success_url: currentUrl,
          skip_init_payment: true
        }
        if (this.isUseCard) {
          formData.start_recurrent = 1
        }

        this.createOrder(formData).then((response) => {
          if (response.status === 200) {
            this.$refs.payment.submit()
          } else {
            this.$notify.error({
              duration: 5000,
              title: `${this.$t('error')} \u{1f62a}`,
              message: response
            })
          }
        })
      } else {
        this.isMirError = true
      }
    },
    unblockCompany(amount) {
      const formData = {
        amount:
          this.tariffType === 'charge_sent_units'
            ? Math.abs(this.billAmount)
            : amount.amount,
        // success_url: 'https://dev.skif.pro/',
        success_url: currentUrl,
        skip_init_payment: true
      }

      const formDataNewTariff = {
        tariff: {
          units: amount.amount / this.tariffPrice,
          length: 30,
          price: this.tariffPrice
        },
        // success_url: 'https://dev.skif.pro/',
        success_url: currentUrl
      }
      this.createNewOrder(formDataNewTariff)
        .then((response) => {
          if (response.status === 200) {
            return this.createOrder(formData)
          }
          throw new Error('Error')
        })
        .then((response) => {
          if (
            this.activeCompany.active_company.billing.company_status === 'TRIAL'
          )
            return
          if (response.status === 200) {
            this.$refs.payment.submit()
          } else {
            this.$notify.error({
              duration: 5000,
              title: `${this.$t('error')} \u{1f62a}`,
              message: response
            })
          }
        })
        .catch((error) => {
          this.$notify.error({
            duration: 5000,
            title: `${this.$t('error')} \u{1f62a}`,
            message: error
          })
          return 'Error'
        })
    },
    sortChange(prop) {
      if (this.sortProp === prop) {
        if (this.sortOrder === 'ascending') {
          this.sortOrder = 'descending'
        } else if (this.sortOrder === 'descending') {
          this.sortOrder = ''
          this.sortProp = ''
        } else {
          this.sortOrder = 'ascending'
        }
      } else {
        this.sortProp = prop
        this.sortOrder = 'ascending'
      }
    },

    async setAutoPay() {
      this.isPayementError = false

      try {
        if (this.isAutoPayActive) {
          if (!this.wasAutoPayActive) {
            await companyApi.startAutoPay()
          }
        } else if (!this.isAutoPayActive && this.wasAutoPayActive) {
          if (!this.isCardDeleted) {
            await companyApi.stopAutoPay()
          }
        }
      } catch (error) {
        this.currentPayementError = error.response.data.message
      }
    },
    handleAutoPayActive(val) {
      this.isAutoPayActive = val
    },
    async handleDeleteCard() {
      this.isAutoPayActive = false
      await companyApi.deleteCard()
      this.isCardDeleted = true
    },
    handleEditCard() {
      this.handleAddCard()
    },
    handleAddCard() {
      this.isCardDeleted = false
      const formData = {
        amount: 1,
        start_recurrent: 1,
        success_url: currentUrl
        // success_url: 'https://app.skif.pro/'
      }
      this.createOrder(formData).then((response) => {
        if (response.status === 200) {
          this.$refs.payment.submit()
        } else {
          this.$notify.error({
            duration: 5000,
            title: `${this.$t('error')} \u{1f62a}`,
            message: response
          })
        }
      })
    },
    async save() {
      await this.setAutoPay()
      if (this.isPayementError) {
        return
      }
      const formData = {
        name: this.settingCompanyForm.name,
        timezone: {
          key: this.settingCompanyForm.tz
        },
        dealer: { id: this.settingCompanyForm.integrator },
        auto_kill_sessions: this.settingCompanyForm.auto_kill_sessions,
        strong_password: this.settingCompanyForm.strong_password,
        efis_retranslate: this.settingCompanyForm.efis_retranslate,
        dateformat: {
          key: this.settingCompanyForm.dateformat,
          value: this.settingCompanyForm.dateformat,
          type: 'dateformats'
        },

        timeformat: {
          key: this.settingCompanyForm.timeformat,
          value: this.settingCompanyForm.timeformat,
          type: 'timeformats'
        }
      }
      // if (this.model.active_company.is_admin_panel) {
      //   formData.integrator = { id: this.settingCompanyForm.integrator }
      // }
      companyApi.settingsCompany(
        this.companysettings.id,
        { formData },
        (response) => {
          this.$store.dispatch('login/getMe')
          this.settingCompanyForm = response.data
          this.settingCompanyForm.auto_kill_sessions =
            response.data.auto_kill_sessions
          this.$emit('change-company', response.data)
          this.$emit('close')
        },
        (error) => {
          this.$showError(error.response.data.message)
        }
      )
    },

    showText() {
      const info = document.querySelector('.text')
      info.classList.add('hovered')
    },

    hideText() {
      const info = document.querySelector('.text')
      info.classList.remove('hovered')
    }
  },

  created() {
    // this.$store
    //   .dispatch('properties/setIntegrator')
    //   .then(() => console.log('int, '))
    if (this.model.active_company.billing) {
      this.billing = true
      this.tariff = this.model.active_company.billing.tariff
      this.amount = this.model.active_company.billing.amount
      this.companyStatus = this.model.active_company.billing.company_status
      this.isAutoPayActive =
        this.model.active_company.billing.auto_pay.is_active

      if (this.isAutoPayActive) {
        this.wasAutoPayActive = true
      }
      const currentDate = moment(this.currentTime)
      const validFrom = moment(this.model.active_company.billing.valid_to)
      const daysLeft = validFrom.diff(currentDate, 'days') + 1
      let days
      if (daysLeft === 1) {
        days = 'день'
      } else if (daysLeft >= 2 && daysLeft <= 4) {
        days = 'дня'
      } else {
        days = 'дней'
      }
      this.daysLeft = `${daysLeft} ${days}`
      this.validFrom = this.model.active_company.billing.valid_from
      this.validTo = this.model.active_company.billing.valid_to
      this.rate = this.model.active_company.billing.rate
      this.period = moment()
        .add(this.model.active_company.billing.tariff.length, 'days')
        .format('DD-MM-YYYY')
    }

    if (this.admin) {
      this.settingCompanyForm.name =
        this.companysettings.name || this.companysettings[0].name

      this.settingCompanyForm.auto_kill_sessions =
        this.companysettings.auto_kill_sessions ||
        this.companysettings[0].auto_kill_sessions

      this.settingCompanyForm.tz =
        (this.companysettings.timezone && this.companysettings.timezone.key) ||
        (this.companysettings[0].timezone &&
          this.companysettings[0].timezone.key) ||
        ''

      this.settingCompanyForm.strong_password =
        this.companysettings.strong_password ||
        this.companysettings[0].strong_password

      this.settingCompanyForm.dateformat =
        (this.companysettings.dateformat &&
          this.companysettings.dateformat.key) ||
        (this.companysettings[0].dateformat &&
          this.companysettings[0].dateformat.key) ||
        ''

      this.settingCompanyForm.timeformat =
        (this.companysettings.timeformat &&
          this.companysettings.timeformat.key) ||
        (this.companysettings[0].timeformat &&
          this.companysettings[0].timeformat.key) ||
        ''
    } else {
      this.settingCompanyForm.name = this.model.active_company.name
      this.settingCompanyForm.tz = this.model.active_company.timezone.key
      this.settingCompanyForm.auto_kill_sessions =
        this.model.active_company.auto_kill_sessions
      this.settingCompanyForm.strong_password =
        this.model.active_company.strong_password
      this.settingCompanyForm.efis_retranslate =
        this.model.active_company.efis_retranslate
      // let company = this.model.companies.filter(
      //   comp => comp.id === this.model.active_company_id
      // )
      // this.settingCompanyForm.dateformat = company[0].dateformat.key
      this.settingCompanyForm.dateformat =
        this.model.active_company.dateformat.key
      this.settingCompanyForm.timeformat =
        this.model.active_company.timeformat.key
      this.$store.dispatch('properties/setIntegrator')
      if (
        this.model.active_company.dealer &&
        this.model.active_company.dealer.name
      ) {
        this.settingCompanyForm.integrator = this.companysettings.id
      }
    }
  },
  mounted() {
    const formattedTime = localStorage.getItem('formattedTime')
    if (formattedTime) {
      this.settingCompanyForm.formatTime = formattedTime
    }
  }
}
</script>
<style>
.widget-time {
  color: #a6aeb8;
  line-height: 140%;
}
.options-container .card-choose {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  color: #7fabe1;
}
.options-container .txt-danger {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  /* identical to box height, or 100% */

  color: #df4747;
}
.history-panel .el-dialog__header {
  padding-bottom: 0;
}
.history-panel .el-dialog__header .el-dialog__title {
  color: #5477a9;
  font-weight: bold;
}
.history-panel-pay .el-dialog {
  width: 524px;
}

.history-panel-pay .price span {
  width: 50px;
}
.history-panel-pay .price .input-container {
  align-items: center;
  justify-content: center;
}
.input-container input {
  width: 375px;
}
.input-container .currency {
  color: #cad6df;
}

.history-panel-pay .price .info {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #bac1c9;
  width: 100%;
  margin-top: 5px;
}
.history-panel-pay .dialog-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  border-top: 1px solid #cad6df;
}
.dialog-footer .cancelBtn {
  width: 140px;
  height: 32px;
  background: #c8d6df;
  border-radius: 6px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  color: #ffffff;
}
.dialog-footer .acceptBtn {
  color: #ffffff;
  width: 218px;
  height: 32px;
  background: #446c9d;
  border-radius: 6px;
}
.mir-container label {
  margin-right: 10px;
}
.mir-container .info-card {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #bac1c9;

  margin-top: 5px;
}
.history-panel-pay .el-dialog__header .el-dialog__title {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 120%;
  color: #436c9d;
}
.greeny {
  color: #4abc96;
}
.redy {
  color: #f56c6c;
}
.saveBtn {
  color: #5477a9;
  font-weight: 600;
}

.cancelBtn {
  color: #cfdbeb;
  font-weight: 600;
}
.tarif-collapse {
  border: none;
}
.tarif-collapse .el-collapse-item__content {
  padding-bottom: 5px;
}
.tarif-collapse .el-collapse-item__header {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  text-transform: uppercase;

  /* #5477A9 */

  color: #5477a9;
}
.nextPayment {
  font-family: Source Sans Pro;
  font-style: normal;

  font-size: 16px;
  line-height: 20px;

  /* green */

  color: #4abc96;
}
.custom-input-payment .el-input__inner {
  font-size: 14px;
}
.strongPassword {
  display: flex;
  align-items: center;
}
.strongPassword .text {
  width: 258px;
  left: 30px;
  top: 40px;
  padding: 6px 8px;
  background-color: #dbebff;
  box-shadow: 0px 2px 8px 0px #00000014;
  font-size: 12px;
  line-height: 14px;
  border-radius: 6px;
  z-index: 1;
  color: #2767b6;
}
.strongPassword .hint-text:hover .text {
  display: block;
}
</style>
